import pino from 'pino';

const logger = pino({
    transport: {
        target: 'pino-pretty',
        options: {
            colorize: false,
            crlf: false,
            destination: './logger.log',
            translateTime: 'SYS:yyyy-mm-dd HH:MM:ss',
            ignore: 'pid,hostname',
            mkdir: true,
        },
    },
});

export default logger;
