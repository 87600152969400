import { checkLogin } from '@context/user/login';
import { GetServerSidePropsContext } from 'next';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { some } from 'lodash';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import Button from '@mui/material/Button';
import fetchJson, { FetchError } from '@lib/src/fetchJson';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import logger from '@src/logger';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import yup from '@src/yup.locale';

function Login() {
    const router = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const isFullWidth = (email: any) => {
        return some(email, (char) => {
            const charCode = char.charCodeAt(0);
            return charCode >= 65281 && charCode <= 65374;
        });
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: yup.object({
            email: yup
                .string()
                .email('無効なメールアドレスです')
                .required('必須です')
                .max(100)
                .matches(/^(?!\s+$)/, 'スペースのみの入力は認められていません')
                .test('check_full_width', '無効なメールアドレスです', (value: any) => !isFullWidth(value)),
            password: yup
                .string()
                .max(20)
                // .matches(/^[a-zA-Z\d!#$%&\(\)=\-{.+*}/@<>?_]+$/, '半角文字のみ\n※ 有効な記号は ! # $ % & ( ) . - + * / < = > ? @ _ { }')
                // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([a-zA-Z\d!#$%&\(\)=\-{.+*}/@<>?_])+$/, '半角文字のみ（大文字・小文字・数字を最低１文字以上）\n※ 有効な記号は ! # $ % & ( ) . - + * / < = > ? @ _ { }')
                .required('必須です'),
        }),
        onSubmit: async (values: any) => {
            const body = {
                username: isFullWidth(values.email) ? 'invalid-email' : values.email,
                password: values.password,
            };

            try {
                setLoading(!loading);
                const response: any = await fetchJson('/api/user/auth/login', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(body),
                });
                if (response?.httpStatus == 200) {
                    if (response.token.role == 'developer' || response.token.role == 'superadmin' || response.token.role == 'admin' || response.token.role == 'personnel') {
                        router.push('/admin');
                    } else if (response.token.role == 'leader' || response.token.role == 'user') {
                        router.push('/user');
                    } else {
                        enqueueSnackbar('ログインに失敗しました', { variant: 'error' });
                    }
                } else {
                    enqueueSnackbar(response.error.message, { variant: 'error' });
                    logger.error('ログイン：' + response.httpStatus + ' ' + response.error.message);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error instanceof FetchError) {
                    enqueueSnackbar(error.data.message, { variant: 'error' });
                } else {
                    logger.error('ログイン：エラーが発生しました');
                }
            }
        },
    });

    return (
        <div className="main-wrapper introduction">
            <Head>
                <title>ログイン</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <div className="introduction0">
                <div className="logo">
                    <Image src="/assets/images/logo.svg" alt="logo" sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" fill priority />
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="text-field">
                        <TextField fullWidth name="email" label="メールアドレス" variant="outlined" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.touched.email && formik.errors.email ? <p className="error-validation-margin-top">※ {formik.errors.email?.toLocaleString()}</p> : null}
                    </div>
                    <div className="text-field">
                        <TextField fullWidth type="password" name="password" label="パスワード" variant="outlined" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                        {formik.touched.password && formik.errors.password ? (
                            <p className="error-validation-margin-top" style={{ whiteSpace: 'pre-wrap' }}>
                                ※ {formik.errors.password?.toLocaleString()}
                            </p>
                        ) : null}
                    </div>
                    <div className="button-field">
                        <Button fullWidth type="submit" size="large" variant="contained">
                            ログイン
                        </Button>
                    </div>
                </form>
                <div className="link-forget">
                    <Link
                        className="link-forget"
                        legacyBehavior
                        href={{
                            pathname: '/password/reset',
                        }}
                    >
                        <a style={{ color: '#51AAFB' }}>パスワードを忘れた方はこちら</a>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={3} dense preventDuplicate autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Login />
        </SnackbarProvider>
    );
}

export const getServerSideProps = checkLogin((context: GetServerSidePropsContext) => {
    return { props: {} };
});
